import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import { ImageCountryWrapper } from "../elements"

const IconCall = ({fixed}) => {
    const data = useStaticQuery(graphql`
        query {
            icon: file(relativePath: { eq: "svg/icon-telf.svg"}) {
                publicURL
            }
        }    
    `)
    return <ImageCountryWrapper>
        <img src={fixed ? fixed : data.icon.publicURL} alt="llamar a kinesióloga" />        
    </ImageCountryWrapper>
}

export default IconCall
