import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import { ImageCountryWrapper } from "../elements"

const IconWhatsapp = ({fixed}) => {
    const data = useStaticQuery(graphql`
        query {
            icon: file(relativePath: { eq: "svg/icon-whatsapp.svg"}) {
                publicURL
            }
        }    
    `)
    return <ImageCountryWrapper>
        <img src={fixed ? fixed : data.icon.publicURL} alt="wasapear a kinesióloga" />        
    </ImageCountryWrapper>
}

export default IconWhatsapp
