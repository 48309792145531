import React from "react"
import {graphql} from "gatsby"
import {MDXRenderer} from "gatsby-plugin-mdx"
import ContainerDetail from "../components/containerDetail"
import FeatureImage from "../components/featureImage"
import Post from "../components/post"
import { DetailH1, DetailP, DetailDate, DetailTags, SocialMediaWrapper, SocialMediaNameWrapper } from "../elements"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"
import SEO from "../components/seo"
import IconCall from "../components/iconCall"
import IconWhatsapp from "../components/iconWhatsapp"
import Modal from "../components/modal"

const singlePost = ({data, whatsappOK}) => {
    const featureImage = data.mdx.frontmatter.featureImage.childImageSharp.fluid
    const seoImage = data.mdx.frontmatter.featureImage.publicURL
    const nombre = data.mdx.frontmatter.name
    const celular = data.mdx.frontmatter.celphone
    const whatsapp = data.mdx.frontmatter.whatsapp
    const prefijo = data.mdx.frontmatter.prefijo
    
    if (whatsapp === 1) {
        whatsappOK = <SocialMediaNameWrapper className="whatsapp"><Link href={'https://api.whatsapp.com/send?phone=+'+prefijo+celular+'&text=Hola%20*'+nombre+'*%20vi%20tu%20anuncio%20en%20*KINESIOLOGAS%20EXTRANJERAS*,%20¿estás%20disponible?'} rel="noopener noreferrer"><IconWhatsapp /> <span>WhatsApp</span></Link></SocialMediaNameWrapper>
    }
    return (
        <>
        <Header />
        <main>        
            <ContainerDetail>
                <SEO 
                    title={data.mdx.frontmatter.title+' | Kinesiólogas extranjeras'}
                    image={seoImage}
                    description={data.mdx.frontmatter.excerpt}
                    keywords={data.mdx.frontmatter.keywords}
                    url={'https://kinesiologasextranjeras.com/'+data.mdx.frontmatter.slug}
                />
                <Post>
                    <div className="container">
                        <DetailH1>{nombre} {celular}, {data.mdx.frontmatter.title}</DetailH1>
                        <DetailTags>
                            <span>{data.mdx.frontmatter.age} | </span>
                            <span>{data.mdx.frontmatter.department} | </span>
                            <span>{data.mdx.frontmatter.district}</span>
                        </DetailTags>
                        <DetailDate>{data.mdx.frontmatter.date}</DetailDate>
                    </div>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    <div className="container">
                        <DetailP>No olvides mencionar que me viste en <Link to="/" rel="noopener noreferrer">Kinesiólogas Extranjeras</Link></DetailP>
                    </div>
                </Post>
                <aside>
                    <FeatureImage fluid={featureImage} />
                    <SocialMediaWrapper>
                        <SocialMediaNameWrapper>
                            <Link
                                href={'tel:+'+prefijo+celular}
                                title="Llamar"
                                rel="noopener noreferrer"
                            >
                                <IconCall /> <span>Llamar</span>
                            </Link>
                        </SocialMediaNameWrapper>
                        {whatsappOK}
                    </SocialMediaWrapper>
                </aside>                            
            </ContainerDetail>
        </main>
        <Footer />
        <Modal />  
    </>
    )
}

export default singlePost

//date(formatString: "MMMM DD, YYYY")
export const pageQuery = graphql`
    query SinglePostQuery($id: String!) {
        mdx(id: {eq: $id}) {
            body
            frontmatter {
              date(formatString: "DD MMMM YYYY", locale: "es")
              excerpt
              slug
              title
              celphone
              prefijo
              whatsapp
              name
              age
              department
              district
              keywords
              featureImage {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
    }
`


