import React from "react"
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"
import { FeatureImageWrapper } from "../elements"


/*
query {
            imageSharp(fixed: {originalName: { eq: "kinesiologas-anunciate-aqui.jpg" }}) {
                fixed {
                    ...GatsbyImageSharpFixed
                }
            }         
        }
*/ 
/*
<Img fixed={fixed ? fixed : data.imageSharp.fixed} style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
        }} />
*/ 
const FeatureImage = ({fluid}) => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "kinesiologas-anunciate-aqui.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return <FeatureImageWrapper>
        <Img fluid={fluid ? fluid : data.imageSharp.fluid} alt="kinesiologas en perú" />        
    </FeatureImageWrapper>
}

export default FeatureImage
